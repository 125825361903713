<template>
    <v-alert
            class="alert"
            v-model="error"
            colored-border
            :type="type"
            dismissible
            border="bottom"
            origin="bottom"
            width="70vw"
    >
        {{ text }}
    </v-alert>
</template>

<script>
    export default {
        name: "CoreAlert",
        methods: {
            hide_alert: function () {
                window.setInterval(() => {
                    this.$store.commit('setAlert', false)
                }, 5000)
            }
        },
        computed: {
            error: {
                get () {
                    return this.$store.state.alert
                },
                set (value) {
                    this.$store.commit('setAlert', value)
                }
            },
            text: {
                get () {
                    return this.$store.state.alertText
                }
            },
            type: {
                get () {
                    return this.$store.state.alertType;
            }
        }
        },
        mounted: function () {
            if(alert){
                this.hide_alert();
            }
        }
    }
</script>

<style scoped lang="stylus">
    .alert
         z-index 999;
         position fixed;
         bottom 3vh
         margin 0 15vw 0 15vw
</style>
